import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';

import { MatDialog } from '@angular/material/dialog';
import { WebPushSubscribeModalComponent } from './web-push-subscribe-modal.component';
import { WebPushService } from './web-push.service';
import { AuthService } from '../../../auth/auth.service';

@Component({
  selector: 'app-web-push-subscribe-trigger',
  standalone: true,
  imports: [],
  template: '',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WebPushSubscribeTriggerComponent implements OnInit {
  constructor(
    private dialog: MatDialog,
    private webPushService: WebPushService,
    private authService: AuthService
  ) {}

  ngOnInit() {
    this.showModalOrUpdateSubscription();
  }

  /**
   * Show requestPermission modal if user is not subscribed and has not declined notifications.
   * If user is subscribed and subscription is older than 1 day, update subscription.
   * */
  showModalOrUpdateSubscription() {
    if (!this.authService.isLoggedIn()) {
      return;
    }

    /*
     * Service workers are not enabled on this device
     * */
    if (!this.webPushService.isSwEnabled()) {
      console.error('Service workers are not enabled on this device');
      return;
    }

    /*
     * Push notification permission is not granted.
     * And last request was more than 1 day ago.
     */
    if (
      !this.webPushService.isNotificationPermissionGranted() &&
      this.webPushService.subscriptionOlderThanDays(1)
    ) {
      this.showModal();
    }

    /*
     * Push notification permission is granted.
     * And last request was more than 1 day ago.
     */
    if (
      this.webPushService.isNotificationPermissionGranted() &&
      this.webPushService.subscriptionOlderThanDays(1)
    ) {
      this.webPushService.requestPermission().subscribe();
    }
  }

  showModal() {
    this.dialog.open(WebPushSubscribeModalComponent, {
      width: '412px',
    });
  }
}
