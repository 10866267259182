import { APP_INITIALIZER, ErrorHandler, isDevMode, LOCALE_ID, NgModule, } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DatePipe, registerLocaleData } from '@angular/common';
import localeUk from '@angular/common/locales/uk';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { AuthService } from './auth/auth.service';
import { AuthModule } from './auth/auth.module';
import { JwtInterceptor } from './auth/interceptors/jwt.interceptor';
import { ApiForbiddenInterceptor } from './auth/interceptors/api-forbidden.interceptor';
import { SocketIoModule } from 'ngx-socket-io';
import { environment } from '../environments/environment';
import { ServiceWorkerModule } from '@angular/service-worker';
import { WebPushSubscribeModalComponent } from './common/modules/web-push/web-push-subscribe-modal.component';
import { WebPushSubscribeTriggerComponent } from './common/modules/web-push/web-push-subscribe-trigger.component';
import * as Sentry from '@sentry/angular-ivy';
import { Router } from '@angular/router';
import { CustomErrorHandler } from './shared/utils/custom-error-handler';
import { DateAdapter, MAT_DATE_LOCALE, MatNativeDateModule } from '@angular/material/core';
import { CustomDateAdapterComponent } from './shared/overrides/custom-date-adapter/custom-date-adapter.component';

registerLocaleData(localeUk);

function initSecurityService(authService: AuthService) {
  return () => {
    return new Promise((resolve) => {
      authService.init().subscribe({
        next: () => {
          resolve(true);
        },
        error: () => {
          resolve(false);
        },
      });
    });
  };
}

const GLOBAL_PIPES = [DatePipe];

@NgModule({
  declarations: [AppComponent],
  imports: [
    SocketIoModule.forRoot({
      url: environment.socketUrl,
      options: {
        transports: ['websocket'],
        reconnection: true,
        auth: {
          token: localStorage.getItem(AuthService.TOKEN_STORAGE_KEY),
          handshake: true,
        },
      },
    }),
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AuthModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: !isDevMode(),
      registrationStrategy: 'registerImmediately',
    }),
    WebPushSubscribeModalComponent,
    WebPushSubscribeTriggerComponent,
    MatNativeDateModule,
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'uk' },
    {
      provide: APP_INITIALIZER,
      useFactory: initSecurityService,
      deps: [AuthService],
      multi: true,
    },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiForbiddenInterceptor,
      multi: true,
    },
    {
      provide: ErrorHandler,
      useClass: CustomErrorHandler,
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {
      },
      deps: [Sentry.TraceService],
      multi: true,
    },
    { provide: DateAdapter, useClass: CustomDateAdapterComponent },
    { provide: MAT_DATE_LOCALE, useValue: 'uk-UA' },
    ...GLOBAL_PIPES,
  ],
  exports: [],
  bootstrap: [AppComponent],
})
export class AppModule {
}
