import { ErrorHandler, inject, Inject, Injectable } from '@angular/core';
import { WINDOW } from '../constants/constants';
import * as Sentry from '@sentry/angular-ivy';
import { LocalStorageService } from '../services/local-storage.service';

@Injectable()
export class CustomErrorHandler implements ErrorHandler {
  private storageService = inject(LocalStorageService);
  private sentryErrorHandler = Sentry.createErrorHandler({
    showDialog: false,
  });

  constructor(@Inject(WINDOW) private window: Window) {
  }

  /**
   * Handle errors
   * @param error
   * this method is called when an error occurs anywhere in the application, and checks if the error is a chunk error
   * if it is, it checks if the last reload was less than 5 seconds ago,
   * if it wasn't, it sets the last reload time to now and reloads the page to get the new chunks
   * if it was, it does nothing
   */
  handleError(error: any): void {
    switch (true) {
      case this.detectChunkError(error):
        this.handleChunkError();
        break;
      default:
        // Delegate handling to Sentry
        this.sentryErrorHandler.handleError(error);
        console.error('Custom Error Handling:', error);
    }
  }

  private detectChunkError(error: any): boolean {
    return (error?.message?.includes('Loading chunk') && error?.message?.includes('failed')) || error?.message?.includes('Failed to fetch dynamically imported module');
  }

  private handleChunkError() {
    const RELOAD_INTERVAL = 5000;
    const now = Date.now();

    // Get the last reload time from localStorage
    const lastReload = parseInt(this.storageService.getItem('lastReloadTime') as string || '0', 10);

    // Check if the last reload was less than 5 seconds ago
    if (now - lastReload > RELOAD_INTERVAL) {
      this.storageService.setItem('lastReloadTime', now.toString());
      this.window.location.reload();
    }
  }
}
