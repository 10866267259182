import { inject, Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, } from '@angular/common/http';
import { catchError, Observable } from 'rxjs';
import { AlertService } from '../../shared/services/alert.service';

@Injectable()
export class ApiForbiddenInterceptor implements HttpInterceptor {
  private alertService: AlertService = inject(AlertService);

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((error) => {
        if (error.status === 403) {
          error.message = 'У доступі до ресурсу відмовлено. Зверніться до керівника.';
          this.alertService.showError(error.message);
        }

        throw error.error;
      })
    );
  }
}
