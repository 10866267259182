import { ChangeDetectionStrategy, Component } from '@angular/core';

import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { WebPushService } from './web-push.service';

@Component({
  selector: 'app-web-push-subscribe-modal',
  standalone: true,
  imports: [MatDialogModule, MatButtonModule, MatIconModule],
  template: `
    <div class="subscribe-modal">
      <mat-dialog-content class="subscribe-modal__content">
        @if (state === 'initial') {
          <div class="text initial">
            <div class="icon"></div>
            <div>
              Отримувати push-сповіщення <br />
              від
              <a href="https://core.luxnet.io" target="_blank">core.luxnet.io</a>?
            </div>
          </div>
        }
    
        @if (state === 'declined') {
          <div class="text declined">
            <div class="icon bell-off"></div>
            <div>
              Відмовившись від push-сповіщень, ти втрачаєш можливість оперативно
              отримувати інформацію щодо процесів в компанії. Отримувати
              сповіщення?
            </div>
          </div>
        }
      </mat-dialog-content>
      <mat-dialog-actions align="end">
        <button
          (click)="no()"
          mat-raised-button
          class="core-button"
          color="accent"
          >
          Ні
        </button>
        <button
          (click)="yes()"
          mat-raised-button
          class="core-button"
          color="primary"
          >
          Так
        </button>
      </mat-dialog-actions>
    </div>
    `,
  styleUrls: ['./web-push-subscribe-trigger.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WebPushSubscribeModalComponent {
  state: 'initial' | 'declined' = 'initial';

  constructor(
    public dialogRef: MatDialogRef<WebPushSubscribeModalComponent>,
    private webPushService: WebPushService
  ) {}

  no() {
    if (this.state === 'declined') {
      this.webPushService.setLastPermissionRequestDate(); // It is important to set last permission request date to prevent asking user again and again
      return this.dialogRef.close(false);
    }

    this.state = 'declined';
  }

  yes() {
    this.webPushService.requestPermission().subscribe((_) => {
      this.dialogRef.close(true);
    });
  }
}
